@font-face {
    font-family: 'Anta';
    src: url("../public/Anta-Regular.ttf") format('truetype');
}
.page{
    height: 100%;
}
#root{
    height: 100%;
}
body{
    font-family: "Anta";
    height: 100vh;
}
.homepage-section{
    width: 100%;
    height: 100%;
    position: relative;
}
.homepage-canvas{
    width: 100%;
    height: 100%;
    background-color: black;
}
.navbar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 30px;
    background-color: transparent;
    color: white;
    position: absolute;
    z-index: 5;
    margin-top: 20px;
}
.navbar-right{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-right: 30px;
}
.logo{
    margin-left: 30px;
}
.navbar-a{
    text-decoration: none;
    cursor: pointer;
}
.navbar-a:hover{
    color: aqua;
}
.text{
    font-family: "Anta";
}
@media screen and (max-width: 540px) {
    .navbar-a{
        font-size: 20px;
    }
}
@media screen and (max-width: 390px) {
    .navbar-a{
        font-size: 13px;
    }
    
}